<template>
  <div>
    <el-dialog
      :visible.sync="isDialog"
      append-to-body
      :width="width + '%'"
      :before-close="cancellationFn"
      v-loading="loading"
    >
      <div slot="title" style="color: #409eff">
        <div class="project-center">
          <div class="before-change">更改前</div>
          <div class="after-the-alteration">更改后</div>
        </div>
      </div>
      <div class="project-center">
        <div class="project-left">
          <el-card>
            <div slot="header">
              <span style="font-weight: 900; color: black">项目基本信息</span>
            </div>
            <el-descriptions class="margin-top" direction="vertical" :column="7" border>
              <el-descriptions-item
                :span="2"
                label="项目名称"
                :contentStyle="
                  form.changeFieldList.includes('projectName')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.projectName }}
              </el-descriptions-item>

              <el-descriptions-item
                label="项目经理"
                :contentStyle="
                  form.changeFieldList.includes('deptClassifyManagerId')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ deptSransition(form.oldData.deptBelongManagerId) }} -
                {{ form.oldData.managerName }}
              </el-descriptions-item>
              <el-descriptions-item
                label="组长"
                :contentStyle="
                  form.changeFieldList.includes('deptLeaderManagerId')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ deptSransition(form.oldData.deptLeaderDeptId) }} -
                {{ form.oldData.leaderManagerName }}
              </el-descriptions-item>

              <el-descriptions-item
                label="部门主管经理"
                :contentStyle="
                  form.changeFieldList.includes('deptCompetentManagerId')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.competentManagerName }}
              </el-descriptions-item>

              <el-descriptions-item
                label="分管高管"
                :contentStyle="
                  form.changeFieldList.includes('deptFzManagerId')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.fzManagerName }}
              </el-descriptions-item>

              <el-descriptions-item
                label="业主联系人"
                :contentStyle="
                  form.changeFieldList.includes('customersName')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.customersName }}
              </el-descriptions-item>

              <el-descriptions-item
                label="业主单位"
                :contentStyle="
                  form.changeFieldList.includes('customersUnit')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.customersUnit }}
              </el-descriptions-item>
              <el-descriptions-item
                label="项目承接公司"
                :contentStyle="
                  form.changeFieldList.includes('companyType')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.companyType | dict(dictData.companyType) }}
              </el-descriptions-item>

              <el-descriptions-item
                label="实施地点"
                :span="2"
                :contentStyle="
                  form.changeFieldList.includes('implementSite')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.implementSite }}
              </el-descriptions-item>

              <el-descriptions-item
                label="预计开始日期"
                :contentStyle="
                  form.changeFieldList.includes('projectPredictStartDate')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.projectPredictStartDate | dateFormat }}
              </el-descriptions-item>
              <el-descriptions-item
                label="预计完成日期"
                :contentStyle="
                  form.changeFieldList.includes('projectPredictEndDate')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.projectPredictEndDate | dateFormat }}
              </el-descriptions-item>

              <el-descriptions-item
                label="实际开始日期"
                :contentStyle="
                  form.changeFieldList.includes('actualPredictStartDate')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.actualPredictStartDate | dateFormat }}
              </el-descriptions-item>

              <el-descriptions-item
                label="实际完成日期"
                :contentStyle="
                  form.changeFieldList.includes('accomplishDate')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.accomplishDate | dateFormat }}
              </el-descriptions-item>

              <el-descriptions-item
                label="要求完成日期"
                :contentStyle="
                  form.changeFieldList.includes('requireAccomplishDate')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.requireAccomplishDate | dateFormat }}
              </el-descriptions-item>

              <!-- <el-descriptions-item
                label="合同名称"
                :contentStyle="
                  form.changeFieldList.includes('agreementName')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.agreementName }}
              </el-descriptions-item> -->

              <el-descriptions-item
                :span="1"
                label="剩余工作量比例(%)"
                :contentStyle="
                  form.changeFieldList.includes('residualWorkRatio')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.residualWorkRatio }}
              </el-descriptions-item>

              <el-descriptions-item
                :span="4"
                label="剩余工作量说明"
                :contentStyle="
                  form.changeFieldList.includes('residualWorkRatioContent')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.residualWorkRatioContent }}
              </el-descriptions-item>
              <el-descriptions-item
                label="备注"
                :contentStyle="
                  form.changeFieldList.includes('remark') ? { 'background-color': '#eef9b2' } : ''
                "
                >{{ form.oldData.remark }}
              </el-descriptions-item>
            </el-descriptions>
          </el-card>
          <el-table
            :data="form.oldData.projectDeptList"
            style="width: 100%; margin-top: 20px"
            border
            :header-cell-class-name="leftHeaderClassName"
            :cell-class-name="leftCellClassName"
          >
            <el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
            <el-table-column prop="deptName" min-width="130" label="部门名称" align="center">
            </el-table-column>
            <el-table-column prop="type" min-width="130" label="部门类型" align="center">
              <template slot-scope="scope">
                {{ transitionDept(scope.row.type) }}
              </template>
            </el-table-column>
            <el-table-column prop="predictAmount" min-width="130" label="预算(元)" align="center">
              <template slot-scope="scope">
                {{ scope.row.predictAmount | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column prop="taskRatio" min-width="100" label="任务占比(%)" align="center">
            </el-table-column>
            <el-table-column prop="taskExplain" min-width="130" label="任务分工说明" align="center">
            </el-table-column>
          </el-table>

          <el-card>
            <div slot="header">
              <span style="font-weight: 900; color: black">业务类型</span>
            </div>
            <el-descriptions
              class="margin-top"
              direction="vertical"
              :column="5"
              border
              style="width: 100%"
            >
              <el-descriptions-item
                label="商务类型"
                :contentStyle="
                  form.changeFieldList.includes('businessType')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.businessType | dict(dictData.businessType) }}
              </el-descriptions-item>
              <!-- <el-descriptions-item
                label="项目阶段"
                :contentStyle="
                  form.changeFieldList.includes('status') ? { 'background-color': '#bff0de' } : ''
                "
              >
                {{ form.oldData.status | dict(dictData.statusList) }}
              </el-descriptions-item> -->
              <el-descriptions-item
                label="项目来源"
                :contentStyle="
                  form.changeFieldList.includes('projectSource')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.projectSource | dict(dictData.projectSource) }}
              </el-descriptions-item>
              <el-descriptions-item
                label="合作费比例(%)"
                v-if="form.oldData.projectSource == 'zi_zhi_he_zuo'"
                :contentStyle="
                  form.changeFieldList.includes('collaborationCostsRatio')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.collaborationCostsRatio }}
              </el-descriptions-item>

              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('businessLines')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                <template slot="label"> 业务种类 </template>
                {{ form.oldData.businessLines | dict(dictData.businessLines) }}
              </el-descriptions-item>

              <el-descriptions-item
                label="项目种类"
                :contentStyle="
                  form.changeFieldList.includes('projectLines')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.projectLines | dict(dictData.projectLines) }}
              </el-descriptions-item>
              <!-- <el-descriptions-item
                label="项目绩效状态"
                :contentStyle="
                  form.changeFieldList.includes('performanceStatus')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.performanceStatus | dict(dictData.performanceStatus) }}
              </el-descriptions-item> -->
            </el-descriptions>
          </el-card>
          <el-card>
            <div slot="header">
              <span style="font-weight: 900; color: black">项目金额</span>
            </div>
            <el-descriptions
              class="margin-top"
              direction="vertical"
              :column="5"
              style="width: 100%"
              border
            >
              <el-descriptions-item
                label="预计合同金额(元)"
                :contentStyle="
                  form.changeFieldList.includes('predictContractAmount')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.predictContractAmount | applyAmount }}
              </el-descriptions-item>

              <el-descriptions-item
                label="实际合同金额(元)"
                :contentStyle="
                  form.changeFieldList.includes('actualContractAmount')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.actualContractAmount | applyAmount }}
              </el-descriptions-item>
              <el-descriptions-item
                label="项目实际金额(元)"
                :contentStyle="
                  form.changeFieldList.includes('actualAmount')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.actualAmount | applyAmount }}
              </el-descriptions-item>

              <el-descriptions-item
                label="招标费(元)"
                :contentStyle="
                  form.changeFieldList.includes('tenderFee')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.tenderFee | applyAmount }}
              </el-descriptions-item>
              <el-descriptions-item
                label="设备折旧费"
                :contentStyle="
                  form.changeFieldList.includes('depreciationEquipmentFee')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.depreciationEquipmentFee | applyAmount }}
              </el-descriptions-item>
            </el-descriptions>
          </el-card>
          <el-card>
            <div slot="header">
              <span style="font-weight: 900; color: black">税费</span>
            </div>
            <el-descriptions
              direction="vertical"
              style="width: 100%"
              :column="6"
              border
              class="margin-top"
            >
              <el-descriptions-item
                label="增值税类型"
                :span="3"
                :contentStyle="
                  form.changeFieldList.includes('vatType') ? { 'background-color': '#bff0de' } : ''
                "
              >
                {{ form.oldData.vatType | transitionType(zzsList) }}
              </el-descriptions-item>
              <el-descriptions-item
                label="增值税类型详细"
                :contentStyle="
                  form.changeFieldList.includes('vatData') ? { 'background-color': '#bff0de' } : ''
                "
              >
                {{ form.oldData.vatData | transitionData(dictData.oldVatData) }}
              </el-descriptions-item>

              <el-descriptions-item
                label="税费(元)"
                :contentStyle="
                  form.changeFieldList.includes('taxesFee') ? { 'background-color': '#bff0de' } : ''
                "
              >
                {{ form.oldData.taxesFee | applyAmount }}
              </el-descriptions-item>
            </el-descriptions>

            <el-descriptions direction="vertical" :column="9" style="width: 100%" border>
              <el-descriptions-item
                label="增值税税率(%)"
                labelClassName="taxLabelClassName"
                :contentStyle="
                  form.changeFieldList.includes('vatRate') ? { 'background-color': '#bff0de' } : ''
                "
              >
                {{ form.oldData.vatRate }}
              </el-descriptions-item>

              <el-descriptions-item label="附加税税率(%)" labelClassName="taxLabelClassName">
                12%
              </el-descriptions-item>

              <el-descriptions-item label="印花税税率(%)" labelClassName="taxLabelClassName">
                0.03%
              </el-descriptions-item>

              <el-descriptions-item
                label="增值税(元)"
                labelClassName="vatLabelClassName"
                :contentStyle="
                  form.changeFieldList.includes('actualVat')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.actualVat | applyAmount }}
              </el-descriptions-item>

              <el-descriptions-item
                label="附加税(元)"
                labelClassName="vatLabelClassName"
                :contentStyle="
                  form.changeFieldList.includes('actualAdditionalTax')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.actualAdditionalTax | applyAmount }}
              </el-descriptions-item>

              <el-descriptions-item
                label="印花税(元)"
                labelClassName="vatLabelClassName"
                :contentStyle="
                  form.changeFieldList.includes('actualStampTax')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                {{ form.oldData.actualStampTax | applyAmount }}
              </el-descriptions-item>
            </el-descriptions>
          </el-card>
          <el-card>
            <div slot="header">
              <span style="font-weight: 900; color: black">预估成本方案</span>
            </div>
            <div class="width : 100%;flex : 1">
              <span class="projectBudgetTitle"> 预估成本金额： </span>
              <span
                :style="
                  form.changeFieldList.includes('projectBudget')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                class="projectBudget"
                >{{ form.oldData.projectBudget | applyAmount }}</span
              >

              <el-table
                :data="form.oldData.projectCostPlanFileList"
                :header-cell-class-name="leftHeaderClassName"
                :cell-class-name="leftCellClassName"
                border
                style="width: 100%"
              >
                <el-table-column prop="fileName" label="文件名称" align="center">
                  <template slot-scope="scope">
                    <a
                      target="_blank"
                      style="text-decoration: revert; color: #409eff"
                      :href="`${filepath}${scope.row.filePath}`"
                      >{{ scope.row.fileName }}</a
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  label="备注"
                  prop="fileContent"
                  align="center"
                  :show-overflow-tooltip="false"
                >
                </el-table-column>
              </el-table>
            </div>
          </el-card>
          <el-card>
            <div slot="header">
              <span style="font-weight: 900; color: black">会议信息</span>
            </div>
            <div
              :style="
                form.changeFieldList.includes('projectApprovalMeetingSummary')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              {{ form.oldData.projectApprovalMeetingSummary }}
            </div>
          </el-card>

          <el-card>
            <div slot="header">
              <span style="font-weight: 900; color: black">外协信息</span>
            </div>
            <el-table
              :data="form.oldData.projectOutsourceList"
              :header-cell-class-name="leftHeaderClassName"
              :cell-class-name="leftCellClassName"
              border
              style="width: 100%"
            >
              <el-table-column align="center" type="index" label="序号" width="50">
              </el-table-column>
              <el-table-column prop="outsourceName" label="外协公司" align="center" width="180">
              </el-table-column>
              <el-table-column prop="content" label="约定内容" align="center" width="220">
              </el-table-column>
              <el-table-column prop="memberQuantity" label="外协人数" align="center" width="120">
              </el-table-column>
              <el-table-column prop="outsourceCost" label="外协费用(元)" align="center" width="170">
                <template slot-scope="scope">
                  {{ scope.row.outsourceCost | applyAmount }}
                </template>
              </el-table-column>
              <el-table-column prop="dockingPeople" label="对接人" align="center" width="110">
              </el-table-column>
              <el-table-column prop="phone" label="对接人电话" align="center" width="190">
              </el-table-column>
              <el-table-column
                prop="projectOutsourceStartDate"
                align="center"
                label="开始时间"
                width="160"
              >
                <template slot-scope="scope">
                  {{ scope.row.projectOutsourceStartDate | dateFormat }}
                </template>
              </el-table-column>
              <el-table-column
                prop="projectOutsourceAccomplishDate"
                align="center"
                label="完成时间"
                width="160"
              >
                <template slot-scope="scope">
                  {{ scope.row.projectOutsourceAccomplishDate | dateFormat }}
                </template>
              </el-table-column>
              <el-table-column prop="appraiseGrade" align="center" label="评价等级" width="110">
              </el-table-column>
              <el-table-column prop="appraise" label="评价说明" width="220"> </el-table-column>
            </el-table>
          </el-card>
        </div>

        <div class="project-right" v-if="form.newData">
          <el-card>
            <div slot="header">
              <span style="font-weight: 900; color: black">项目基本信息</span>
            </div>
            <el-descriptions class="margin-top" direction="vertical" :column="7" border>
              <el-descriptions-item
                :span="2"
                label="项目名称"
                :contentStyle="
                  form.changeFieldList.includes('projectName')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.projectName }}
              </el-descriptions-item>

              <el-descriptions-item
                label="项目经理"
                :contentStyle="
                  form.changeFieldList.includes('deptClassifyManagerId')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ deptSransition(form.newData.deptBelongManagerId) }} -
                {{ form.newData.managerName }}
              </el-descriptions-item>
              <el-descriptions-item
                label="组长"
                :contentStyle="
                  form.changeFieldList.includes('deptLeaderManagerId')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ deptSransition(form.newData.deptLeaderDeptId) }} -
                {{ form.newData.leaderManagerName }}
              </el-descriptions-item>

              <el-descriptions-item
                label="部门主管经理"
                :contentStyle="
                  form.changeFieldList.includes('deptCompetentManagerId')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.competentManagerName }}
              </el-descriptions-item>

              <el-descriptions-item
                label="分管高管"
                :contentStyle="
                  form.changeFieldList.includes('deptFzManagerId')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.fzManagerName }}
              </el-descriptions-item>

              <el-descriptions-item
                label="业主联系人"
                :contentStyle="
                  form.changeFieldList.includes('customersName')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.customersName }}
              </el-descriptions-item>

              <el-descriptions-item
                label="业主单位"
                :contentStyle="
                  form.changeFieldList.includes('customersUnit')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.customersUnit }}
              </el-descriptions-item>
              <el-descriptions-item
                label="项目承接公司"
                :contentStyle="
                  form.changeFieldList.includes('companyType')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.companyType | dict(dictData.companyType) }}
              </el-descriptions-item>

              <el-descriptions-item
                label="实施地点"
                :span="2"
                :contentStyle="
                  form.changeFieldList.includes('implementSite')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.implementSite }}
              </el-descriptions-item>

              <el-descriptions-item
                label="预计开始日期"
                :contentStyle="
                  form.changeFieldList.includes('projectPredictStartDate')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.projectPredictStartDate | dateFormat }}
              </el-descriptions-item>
              <el-descriptions-item
                label="预计完成日期"
                :contentStyle="
                  form.changeFieldList.includes('projectPredictEndDate')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.projectPredictEndDate | dateFormat }}
              </el-descriptions-item>

              <el-descriptions-item
                label="实际开始日期"
                :contentStyle="
                  form.changeFieldList.includes('actualPredictStartDate')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.actualPredictStartDate | dateFormat }}
              </el-descriptions-item>

              <el-descriptions-item
                label="实际完成日期"
                :contentStyle="
                  form.changeFieldList.includes('accomplishDate')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.accomplishDate | dateFormat }}
              </el-descriptions-item>

              <el-descriptions-item
                label="要求完成日期"
                :contentStyle="
                  form.changeFieldList.includes('requireAccomplishDate')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.requireAccomplishDate | dateFormat }}
              </el-descriptions-item>

              <!-- <el-descriptions-item
                label="合同名称"
                :contentStyle="
                  form.changeFieldList.includes('agreementName')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.agreementName }}
              </el-descriptions-item> -->

              <el-descriptions-item
                :span="1"
                label="剩余工作量比例(%)"
                :contentStyle="
                  form.changeFieldList.includes('residualWorkRatio')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.residualWorkRatio }}
              </el-descriptions-item>

              <el-descriptions-item
                :span="4"
                label="剩余工作量说明"
                :contentStyle="
                  form.changeFieldList.includes('residualWorkRatioContent')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.residualWorkRatioContent }}
              </el-descriptions-item>
              <el-descriptions-item
                label="备注"
                :contentStyle="
                  form.changeFieldList.includes('remark') ? { 'background-color': '#eef9b2' } : ''
                "
              >
                {{ form.newData.remark }}
              </el-descriptions-item>
            </el-descriptions>
          </el-card>
          <el-table
            :data="form.newData.projectDeptList"
            style="width: 100%; margin-top: 20px"
            border
            :header-cell-class-name="rightHeaderClassName"
            :cell-class-name="rightCellClassName"
          >
            <el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
            <el-table-column prop="deptName" min-width="130" label="部门名称" align="center">
            </el-table-column>
            <el-table-column prop="type" min-width="130" label="部门类型" align="center">
              <template slot-scope="scope">
                {{ transitionDept(scope.row.type) }}
              </template>
            </el-table-column>
            <el-table-column prop="predictAmount" min-width="130" label="预算(元)" align="center">
              <template slot-scope="scope">
                {{ scope.row.predictAmount | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column prop="taskRatio" min-width="100" label="任务占比(%)" align="center">
            </el-table-column>
            <el-table-column prop="taskExplain" min-width="130" label="任务分工说明" align="center">
            </el-table-column>
          </el-table>

          <el-card>
            <div slot="header">
              <span style="font-weight: 900; color: black">业务类型</span>
            </div>
            <el-descriptions
              class="margin-top"
              direction="vertical"
              :column="6"
              border
              style="width: 100%"
            >
              <el-descriptions-item
                label="商务类型"
                :contentStyle="
                  form.changeFieldList.includes('businessType')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.businessType | dict(dictData.businessType) }}
              </el-descriptions-item>
              <!-- <el-descriptions-item
                label="项目阶段"
                :contentStyle="
                  form.changeFieldList.includes('status') ? { 'background-color': '#eef9b2' } : ''
                "
              >
                {{ form.newData.status | dict(dictData.statusList) }}
              </el-descriptions-item> -->
              <el-descriptions-item
                label="项目来源"
                :contentStyle="
                  form.changeFieldList.includes('projectSource')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.projectSource | dict(dictData.projectSource) }}
              </el-descriptions-item>
              <el-descriptions-item
                label="合作费比例(%)"
                v-if="form.newData.projectSource == 'zi_zhi_he_zuo'"
                :contentStyle="
                  form.changeFieldList.includes('collaborationCostsRatio')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.collaborationCostsRatio }}
              </el-descriptions-item>

              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('businessLines')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                <template slot="label"> 业务种类 </template>
                {{ form.newData.businessLines | dict(dictData.businessLines) }}
              </el-descriptions-item>

              <el-descriptions-item
                label="项目种类"
                :contentStyle="
                  form.changeFieldList.includes('projectLines')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.projectLines | dict(dictData.projectLines) }}
              </el-descriptions-item>
              <!-- <el-descriptions-item
                label="项目绩效状态"
                :contentStyle="
                  form.changeFieldList.includes('performanceStatus')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.performanceStatus | dict(dictData.performanceStatus) }}
              </el-descriptions-item> -->
            </el-descriptions>
          </el-card>
          <el-card>
            <div slot="header">
              <span style="font-weight: 900; color: black">项目金额</span>
            </div>
            <el-descriptions
              class="margin-top"
              direction="vertical"
              :column="5"
              style="width: 100%"
              border
            >
              <el-descriptions-item
                label="预计合同金额(元)"
                :contentStyle="
                  form.changeFieldList.includes('predictContractAmount')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.predictContractAmount | applyAmount }}
              </el-descriptions-item>

              <el-descriptions-item
                label="实际合同金额(元)"
                :contentStyle="
                  form.changeFieldList.includes('actualContractAmount')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.actualContractAmount | applyAmount }}
              </el-descriptions-item>
              <el-descriptions-item
                label="项目实际金额(元)"
                :contentStyle="
                  form.changeFieldList.includes('actualAmount')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.actualAmount | applyAmount }}
              </el-descriptions-item>

              <el-descriptions-item
                label="招标费(元)"
                :contentStyle="
                  form.changeFieldList.includes('tenderFee')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.tenderFee | applyAmount }}
              </el-descriptions-item>
              <el-descriptions-item
                label="设备折旧费"
                :contentStyle="
                  form.changeFieldList.includes('depreciationEquipmentFee')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.depreciationEquipmentFee | applyAmount }}
              </el-descriptions-item>
            </el-descriptions>
          </el-card>
          <el-card>
            <div slot="header">
              <span style="font-weight: 900; color: black">税费</span>
            </div>
            <el-descriptions
              direction="vertical"
              style="width: 100%"
              :column="6"
              border
              class="margin-top"
            >
              <el-descriptions-item
                label="增值税类型"
                :span="3"
                :contentStyle="
                  form.changeFieldList.includes('vatType') ? { 'background-color': '#eef9b2' } : ''
                "
              >
                {{ form.newData.vatType | transitionType(zzsList) }}
              </el-descriptions-item>
              <el-descriptions-item
                label="增值税类型详细"
                :contentStyle="
                  form.changeFieldList.includes('vatData') ? { 'background-color': '#eef9b2' } : ''
                "
              >
                {{ form.newData.vatData | transitionData(dictData.oldVatData) }}
              </el-descriptions-item>

              <el-descriptions-item
                label="税费(元)"
                :contentStyle="
                  form.changeFieldList.includes('taxesFee') ? { 'background-color': '#eef9b2' } : ''
                "
              >
                {{ form.newData.taxesFee | applyAmount }}
              </el-descriptions-item>
            </el-descriptions>

            <el-descriptions direction="vertical" :column="9" style="width: 100%" border>
              <el-descriptions-item
                label="增值税税率(%)"
                labelClassName="taxLabelClassName"
                :contentStyle="
                  form.changeFieldList.includes('vatRate') ? { 'background-color': '#eef9b2' } : ''
                "
              >
                {{ form.newData.vatRate }}
              </el-descriptions-item>

              <el-descriptions-item label="附加税税率(%)" labelClassName="taxLabelClassName">
                12%
              </el-descriptions-item>

              <el-descriptions-item label="印花税税率(%)" labelClassName="taxLabelClassName">
                0.03%
              </el-descriptions-item>

              <el-descriptions-item
                label="增值税(元)"
                labelClassName="vatLabelClassName"
                :contentStyle="
                  form.changeFieldList.includes('actualVat')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.actualVat | applyAmount }}
              </el-descriptions-item>

              <el-descriptions-item
                label="附加税(元)"
                labelClassName="vatLabelClassName"
                :contentStyle="
                  form.changeFieldList.includes('actualAdditionalTax')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.actualAdditionalTax | applyAmount }}
              </el-descriptions-item>

              <el-descriptions-item
                label="印花税(元)"
                labelClassName="vatLabelClassName"
                :contentStyle="
                  form.changeFieldList.includes('actualStampTax')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                {{ form.newData.actualStampTax | applyAmount }}
              </el-descriptions-item>
            </el-descriptions>
          </el-card>
          <el-card>
            <div slot="header">
              <span style="font-weight: 900; color: black">预估成本方案</span>
            </div>
            <div class="width : 100%;flex : 1">
              <span class="projectBudgetTitle"> 预估成本金额： </span>
              <span
                :style="
                  form.changeFieldList.includes('projectBudget')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
                class="projectBudget"
                >{{ form.newData.projectBudget | applyAmount }}</span
              >
              <el-table
                :data="form.newData.projectCostPlanFileList"
                :header-cell-class-name="rightHeaderClassName"
                :cell-class-name="rightCellClassName"
                border
                style="width: 100%"
              >
                <el-table-column prop="fileName" label="文件名称" align="center">
                  <template slot-scope="scope">
                    <a
                      target="_blank"
                      style="text-decoration: revert; color: #409eff"
                      :href="`${filepath}${scope.row.filePath}`"
                      >{{ scope.row.fileName }}</a
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  label="备注"
                  align="center"
                  prop="fileContent"
                  :show-overflow-tooltip="false"
                >
                </el-table-column>
              </el-table>
            </div>
          </el-card>
          <el-card>
            <div slot="header">
              <span style="font-weight: 900; color: black">会议信息</span>
            </div>
            <div
              :style="
                form.changeFieldList.includes('projectApprovalMeetingSummary')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              {{ form.newData.projectApprovalMeetingSummary }}
            </div>
          </el-card>
          <el-card>
            <div slot="header">
              <span style="font-weight: 900; color: black">外协信息</span>
            </div>
            <el-table
              :data="form.newData.projectOutsourceList"
              :header-cell-class-name="rightHeaderClassName"
              :cell-class-name="rightCellClassName"
              border
              style="width: 100%"
            >
              <el-table-column align="center" type="index" label="序号" width="50">
              </el-table-column>
              <el-table-column prop="outsourceName" label="外协公司" align="center" width="180">
              </el-table-column>
              <el-table-column prop="content" label="约定内容" align="center" width="220">
              </el-table-column>
              <el-table-column prop="memberQuantity" label="外协人数" align="center" width="120">
              </el-table-column>
              <el-table-column prop="outsourceCost" label="外协费用(元)" align="center" width="170">
                <template slot-scope="scope">
                  {{ scope.row.outsourceCost | applyAmount }}
                </template>
              </el-table-column>
              <el-table-column prop="dockingPeople" label="对接人" align="center" width="110">
              </el-table-column>
              <el-table-column prop="phone" label="对接人电话" align="center" width="190">
              </el-table-column>
              <el-table-column
                prop="projectOutsourceStartDate"
                align="center"
                label="开始时间"
                width="160"
              >
                <template slot-scope="scope">
                  {{ scope.row.projectOutsourceStartDate | dateFormat }}
                </template>
              </el-table-column>
              <el-table-column
                prop="projectOutsourceAccomplishDate"
                align="center"
                label="完成时间"
                width="160"
              >
                <template slot-scope="scope">
                  {{ scope.row.projectOutsourceAccomplishDate | dateFormat }}
                </template>
              </el-table-column>
              <el-table-column prop="appraiseGrade" align="center" label="评价等级" width="110">
              </el-table-column>
              <el-table-column prop="appraise" label="评价说明" width="220"> </el-table-column>
            </el-table>
          </el-card>
        </div>
      </div>

      <span name="footer" slot="footer" style="display: flex; justify-content: center">
        <el-button
          type="primary"
          style="min-width: 120px; margin: 0 25px"
          plain
          :loading="loading"
          @click="cancellationFn"
          >返 回</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  components: {
    Dictionary: () => import('@/components/Dictionary.vue'),
    VatDictionary: () => import('@/components/VatDictionary.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    SelectDialog: () => import('@/components/selectDialog.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 80,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      isDialog: false,
      loading: false,
      deptData: [], //部门列表
      zzsList: [], //增值税类型
      dictData: {
        companyType: [], //项目承接 COMPANY_TYPE
        projectDept: [], //部门类型 Project_Dept
        businessType: [], //商务类型 BUSINESS_TYPE
        // statusList: [], //项目阶段 PROJECT_STATUS
        projectSource: [], //项目来源 PROJECT_SOURCE
        businessLines: [], //业务种类 BUSINESS_LINES
        projectLines: [], //项目种类 .businessLines
        // performanceStatus: [], //项目绩效状态 PROJECT_PERFORMANCE_STATUS
        oldVatData: [], //增值税详情 .vatType
        newVatData: [], //增值税详情
      },
      form: {
        changeFieldList: [], //变更字段
        newData: {}, //	新数据
        oldData: {}, //	旧数据
      },
      filepath: process.env.VUE_APP_FILEPATH,
    }
  },
  watch: {
    isShow: {
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.getData()
        }
        this.isDialog = newVal
      },
    },
  },
  computed: {
    ...mapState({
      CompanyMembers: state => state.project.CompanyMembers,
    }),
    oldTaxesFee() {
      let num = 0
      num =
        Number(this.form.oldData.actualVat) +
        Number(this.form.oldData.actualAdditionalTax) +
        Number(this.form.oldData.actualStampTax)
      return num
    },
    newTaxesFee() {
      let num = 0
      num =
        Number(this.form.newData.actualVat) +
        Number(this.form.newData.actualAdditionalTax) +
        Number(this.form.newData.actualStampTax)
      return num
    },
  },
  filters: {
    dateFormat(value) {
      if (value) {
        value = Number(value)
        return new Date(value).format('yyyy-MM-dd')
      } else {
        return ''
      }
    },
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return 0
      }
    },
    transitionType(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatType == val)
        str = item?.vatName
      }
      return str
    },
    transitionData(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatData == val)
        str = item?.vatName
      }
      return str
    },
  },
  created() {
    this.getType()
  },
  mounted() {},
  methods: {
    leftCellClassName({ row, column, rowIndex, columnIndex }) {
      if (this.form.changeFieldList.indexOf(column.property) != -1) {
        return ['leftHeaderClassName']
      }
      return []
    },
    leftHeaderClassName({ row, column, rowIndex, columnIndex }) {
      if (this.form.changeFieldList.indexOf(column.property) != -1) {
        return ['leftHeaderClassName']
      }
      return []
    },
    rightCellClassName({ row, column, rowIndex, columnIndex }) {
      if (this.form.changeFieldList.indexOf(column.property) != -1) {
        return ['rightHeaderClassName']
      }
      return []
    },
    rightHeaderClassName({ row, column, rowIndex, columnIndex }) {
      if (this.form.changeFieldList.indexOf(column.property) != -1) {
        return ['rightHeaderClassName']
      }
      return []
    },
    // 部门类型转换
    transitionDept(val) {
      if (val && this.dictData.projectDept && this.dictData.projectDept.length > 0) {
        const item = this.dictData.projectDept.find(v => v.dictVal == val)
        if (item) {
          return item.dictName
        } else {
          return ''
        }
      }
    },
    // 部门ID转换
    deptSransition(id) {
      if (id && this.deptData && this.deptData.length > 0) {
        const item = this.deptData.find(v => v.id == id)
        if (item) {
          return item.deptName
        } else {
          return ''
        }
      }
    },
    getData() {
      this.loading = true
      this.$api.log
        .selectDetailById(this.options.id)
        .then(res => {
          if (res.data) {
            this.form = res.data
          }

          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    getType() {
      //项目种类 .businessLines
      this.$api.dict
        .listSysDictData(this.form.newData.businessLines, true)
        .then(res => {
          this.dictData.projectLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      //业务种类 BUSINESS_LINES
      this.$api.dict
        .listSysDictData('BUSINESS_LINES', true)
        .then(res => {
          this.dictData.businessLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      //商务类型 BUSINESS_TYPE
      this.$api.dict
        .listSysDictData('BUSINESS_TYPE', true)
        .then(res => {
          this.dictData.businessType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      //项目阶段 PROJECT_STATUS
      // this.$api.dict
      //   .listSysDictData('PROJECT_STATUS', true)
      //   .then(res => {
      //     this.dictData.statusList = res.data
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
      //项目绩效状态 PROJECT_PERFORMANCE_STATUS
      // this.$api.dict
      //   .listSysDictData('PROJECT_PERFORMANCE_STATUS', true)
      //   .then(res => {
      //     this.dictData.performanceStatus = res.data
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
      //增值税详情 this.form.oldData.vatType
      this.$api.vat
        .getVatDataList({ vatType: this.form.oldData.vatType, pageNow: 1, pageSize: 999 })
        .then(res => {
          if (res.data?.records) {
            this.dictData.oldVatData = res.data.records
          }
        })
      //增值税详情 this.form.newData.vatType
      this.$api.vat
        .getVatDataList({ vatType: this.form.newData.vatType, pageNow: 1, pageSize: 999 })
        .then(res => {
          if (res.data?.records) {
            this.dictData.newVatData = res.data.records
          }
        })
        .catch(err => {
          console.log(err)
        })
      //项目承接 COMPANY_TYPE
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      //增值税类型
      this.$api.vat
        .getVatTypeList()
        .then(res => {
          this.zzsList = res?.data?.records
        })
        .catch(err => {
          console.log(err)
        })
      //部门类型 Project_Dept
      this.$api.dict
        .listSysDictData('Project_Dept', true)
        .then(res => {
          this.dictData.projectDept = res.data
        })
        .catch(err => {
          console.log(err)
        })
      //项目来源 PROJECT_SOURCE
      this.$api.dict
        .listSysDictData('PROJECT_SOURCE', true)
        .then(res => {
          this.dictData.projectSource = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.sysDept
        .listDept()
        .then(res => {
          this.deptData = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    cancellationFn() {
      this.loading = true
      this.$emit('update:isShow', false)
      this.loading = false
    },
    changeMoneyToChinese(money) {
      //汉字的数字
      var cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
      //基本单位
      var cnIntRadice = ['', '拾', '佰', '仟']
      //对应整数部分扩展单位
      var cnIntUnits = ['', '万', '亿', '兆']
      //对应小数部分单位
      var cnDecUnits = ['角', '分', '毫', '厘']
      //整数金额时后面跟的字符
      var cnInteger = '整'
      //整型完以后的单位
      var cnIntLast = '圆'
      //最大处理的数字
      var maxNum = 999999999999999.9999
      //金额整数部分
      var integerNum
      //金额小数部分
      var decimalNum
      //输出的中文金额字符串
      var chineseStr = ''
      //分离金额后用的数组，预定义
      var parts
      if (money == '') {
        return ''
      }
      money = parseFloat(money)
      if (money >= maxNum) {
        //超出最大处理数字
        return ''
      }
      if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger
        return chineseStr
      }
      //转换为字符串
      money = money.toString()
      if (money.indexOf('.') == -1) {
        integerNum = money
        decimalNum = ''
      } else {
        parts = money.split('.')
        integerNum = parts[0]
        decimalNum = parts[1].substr(0, 4)
      }
      //获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0
        var IntLen = integerNum.length
        for (var i = 0; i < IntLen; i++) {
          var n = integerNum.substr(i, 1)
          var p = IntLen - i - 1
          var q = p / 4
          var m = p % 4
          if (n == '0') {
            zeroCount++
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0]
            }
            //归零
            zeroCount = 0
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
          }
          if (m == 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q]
          }
        }
        chineseStr += cnIntLast
      }
      //小数部分
      if (decimalNum != '') {
        var decLen = decimalNum.length
        for (var i = 0; i < decLen; i++) {
          var n = decimalNum.substr(i, 1)
          if (n != '0') {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i]
          }
        }
      }
      if (chineseStr == '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger
      } else if (decimalNum == '') {
        chineseStr += cnInteger
      }
      return chineseStr
    },
  },
}
</script>

<style lang="scss" scoped>
.project-center {
  display: flex;
  justify-content: flex-start;
  max-height: 500px;
  overflow-y: auto;
  .project-left {
    margin-right: 10px;
    width: 49%;
  }
  .project-right {
    margin-left: 10px;
    width: 49%;
  }

  .radio-group {
    display: flex;
    align-items: center;
    height: 45px;
  }
  .radio-label {
    width: 100px;
    font-size: 14px;
  }
}
.before-change {
  font-weight: 900;
  font-size: 18px;
  margin-right: 47%;
  color: blue;
}
.after-the-alteration {
  font-weight: 900;
  font-size: 18px;
  color: red;
  // #eef9b2
}
/deep/.leftHeaderClassName {
  background-color: #bff0de !important;
}
/deep/.rightHeaderClassName {
  background-color: #eef9b2 !important;
}
.projectBudgetTitle {
  display: inline-block;
  line-height: 40px;
  width: 20%;
  padding-left: 5px;
  border: 1px solid #ebeef5;
}
.projectBudget {
  display: inline-block;
  line-height: 40px;
  padding-left: 8px;
  width: 80%;
  border: 1px solid #ebeef5;
}
</style>
